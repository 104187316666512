import { template as template_901aade74f6f490c8db1d7acd31c76ef } from "@ember/template-compiler";
const WelcomeHeader = template_901aade74f6f490c8db1d7acd31c76ef(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
