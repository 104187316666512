import { template as template_56d18e27316e496384a53d424b719dc7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_56d18e27316e496384a53d424b719dc7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
